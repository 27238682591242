import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import TextHeading from '@components/TextHeading';
import InformationList from '@components/InformationList';

const About = () => {
  const aboutList = useStaticQuery(
    graphql`
      {
        allJsonJson {
          nodes {
            about_articles {
              id
              headerTitle
              list {
                imageUrl {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                id
                title
                sections {
                  id
                  type
                  value
                }
              }
            }
          }
        }
      }
    `,
  );

  const { headerTitle, list } = aboutList.allJsonJson.nodes.find(
    ({ about_articles }) => !!about_articles,
  ).about_articles;

  const informationList = useMemo(() => {
    const defaultProps = {
      listCssRules: 'max-width: 1088px',
      isImageLinkDisabled: true,
      list,
    };

    return <InformationList {...defaultProps} />;
  }, [list, headerTitle]);

  const descriptionText = list
    && list[0].sections.find(({ type }) => type === 'text').value.slice(0, 160);

  return (
    <>
      <Helmet>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <TextHeading title={headerTitle} />
      {informationList}
    </>
  );
};

export default About;
